<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<!-- (구)스피커 - CCTV 관리 -->
			<b>스피커-CCTV 관계 설정</b>
		</div>
		<div>
			<div id="treeViewExample">
				<div class="demo-section wide k-content">
					<div class="treeview-flex">
						<div id="treeview-kendo"></div>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="wrapper">
					<h5 class="panel-title" style="padding-bottom: 10px; padding-top: 50px"><b>스피커-CCTV 관계 설정</b></h5>
					<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center">
						<div style="margin-right: 30px"><h6>스피커 사용 여부</h6></div>
						<div style="display: flex; align-items: center">
							<input type="checkbox" class="switch" id="speaker_use_switch" v-model="speakerInfo.useYn" true-value="1" false-value="0" />
							<label for="speaker_use_switch" class="switch_label">
								<div class="on" v-if="speakerInfo.useYn == 1">on</div>
								<div class="off" v-else>off</div>
								<span class="onf_btn"></span>
							</label>
						</div>
					</div>

					<div class="table-wrapper" warnWork>
						<table class="table table-hover">
							<thead>
								<tr>
									<th width="18px">CCTV 상태</th>
									<th width="50px">CCTV</th>
									<th width="120px">
										전체 선택&nbsp;
										<input type="checkbox" v-model="clickedAllCheckedEvent" @input="changeAllChecked()" />
									</th>
									<th width="50px">연결된 스피커</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="row in cctvInfo" :key="row.cctvId">
									<td :class="row.useYn == '1' ? 'cctv-on' : 'cctv-off'">
										{{ row.useYn == '1' ? 'on' : 'off' }}
									</td>
									<td style="text-align: left">
										{{ row.cctvNm }}
									</td>
									<td>
										<input v-if="row.speakerId == speakerInfo.speakerId" type="checkbox" v-model="row.checked" />
									</td>
									<td>-</td>
								</tr>
								<tr v-for="row in alreadyConnectedCctvInfo" :key="row.cctvId" class="disable">
									<td :class="row.useYn == '1' ? 'cctv-on' : 'cctv-off'">
										{{ row.useYn == '1' ? 'on' : 'off' }}
									</td>
									<td style="text-align: left">
										{{ row.cctvNm }}
									</td>
									<td></td>
									<td>{{ row.speakerNm }}</td>
								</tr>
								<tr empty>
									<td colspan="2">조회된 항목이 없습니다.</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mb-10 mt-10 text-right">
						<button @click="insSpeakerCctv" class="btn btn-labeled bg-primary">
							<b><i class="icon-checkmark3"></i></b>
							적용
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
const speakerCctvApi = apiIndex.speakerCctv;
const speakerApi = apiIndex.speaker;

export default {
	components: {},
	data: () => ({
		pageParam: null,
		siteAndSpeakerInfo: [], // 트리에 표시, 사용할 데이터
		dataSource: [], // 파싱하여 트리에 보여줄 데이터 소스
		speakerInfo: {}, // 스피커 정보
		cctvInfo: [], // 다른 스피커에 연결되어 있지 않은 CCTV 정보
		alreadyConnectedCctvInfo: [], // 다른 스피커에 이미 연결되어 있는 CCTV 정보
		clickedAllCheckedEvent: false, // 전체 선택 이벤트 발생 여부
		loginUserSiteId: null,
	}),
	computed: {
		checkboxStatus() {
			return this.cctvInfo.every(item => item.checked == true);
		},
	},
	watch: {
		checkboxStatus() {
			if (this.checkboxStatus == true) {
				this.clickedAllCheckedEvent = true;
			} else {
				this.clickedAllCheckedEvent = false;
			}
		},
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('speakerCctvPageParam'));

		if (!this.pageParam) location.href = apiIndex.mainUrl;

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.loginUserSiteId = this.pageParam.loginUserSiteId;
	},
	mounted() {
		this.getTree();
	},
	methods: {
		async getTree() {
			await this.getTreeData();
			this.parsingDataSource();
			// 세팅된 데이터로 트리 그리기
			this.drawTreeView();
		},
		// 트리에 표시, 사용할 데이터 불러오기
		async getTreeData() {
			await this.$axios
				.post(speakerCctvApi.inqSiteAndSpeakerInfo)
				.then(
					function (r) {
						this.siteAndSpeakerInfo = r.data;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		// 가져온 데이터로 DataSource에 파싱하기
		parsingDataSource() {
			// 현장 밑에 스피커 설정
			for (let siteElement of this.siteAndSpeakerInfo) {
				let site = {};
				site.id = siteElement.siteId;
				site.text = siteElement.siteNm;
				site.expanded = true;
				site.spriteCssClass = 'site';
				site.key = siteElement.siteId;

				let speakerItems = [];
				if (Object.keys(siteElement.speakerInfo).length) {
					for (let speakerKey in siteElement.speakerInfo) {
						let speakerItem = {};
						speakerItem.id = speakerKey;
						speakerItem.text = siteElement.speakerInfo[speakerKey];
						speakerItem.expanded = false;
						speakerItem.spriteCssClass = 'speaker';
						speakerItem.key = speakerKey;
						speakerItem.parentSiteId = site.id;
						speakerItem.parentSiteNm = site.text;
						speakerItems.push(speakerItem);
					}

					site.items = speakerItems;
				}

				this.dataSource.push(site);
			}
		},
		drawTreeView() {
			$('#treeview-kendo').kendoTreeView({
				dataSource: this.dataSource,
				loadOnDemand: true,
				select: this.treeSelect,
			});

			if (this.loginUserSiteId != null) {
				const that = this;
				$('#treeview-kendo')
					.find('ul > li')
					.each(function () {
						// 로그인한 현장과 같은 현장이 있고
						if ($('#treeview-kendo').data('kendoTreeView').dataItem($(this)).parentSiteId === that.loginUserSiteId) {
							// 젤 처음 나오는 스피커면
							if ($('#treeview-kendo').data('kendoTreeView').dataItem($(this)).spriteCssClass === 'speaker') {
								// 클릭 이벤트 발생
								$(this).find('.k-in').trigger('click');
								return false;
							}
						}
					});
			}
		},
		// 트리 클릭 시
		async treeSelect(e) {
			let data = $('#treeview-kendo').data('kendoTreeView').dataItem(e.node);
			this.clickedAllCheckedEvent = false; // 전체 선택 버튼 초기화
			if (data.spriteCssClass == 'speaker') {
				// 화면에 보여줄 CCTV 정보
				await this.$axios
					.post(speakerCctvApi.inqAllCctvAndConnectInfoFindBySiteId, { siteId: data.parentSiteId, speakerId: data.id })
					.then(
						function (r) {
							this.cctvInfo = r.data.cctvInfo;
							this.alreadyConnectedCctvInfo = r.data.alreadyConnectedCctvInfo;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());

				//해당 스피커 정보
				await this.$axios
					.post(speakerApi.inqOneSpeaker, { speakerId: data.id })
					.then(
						function (r) {
							this.speakerInfo = r.data;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
				// 스피커 CCTV 관계 정보
				await this.$axios
					.post(speakerCctvApi.inqSpeakerCctvFindBySpeakerId, { siteId: data.parentSiteId, speakerId: data.id })
					.then(
						function (r) {
							// 받아온 스피커 CCTV 관계 정보
							let speakerCctvInfo = r.data;
							// CCTV 정보가 있으면
							if (this.cctvInfo.length) {
								for (let cctvElement of this.cctvInfo) {
									// CCTV 정보에 스피커 id 넣기, insert시 사용
									cctvElement.speakerId = data.id;
									// 받아온 스피커 CCTV 관계 정보가 있으면
									if (speakerCctvInfo.length) {
										for (let speakerCctvElement of speakerCctvInfo) {
											if (cctvElement.cctvId === speakerCctvElement.cctvId) {
												// 등록된 데이터는 체크 상태로
												if (speakerCctvElement.useYn === '1') {
													this.$set(cctvElement, 'checked', true);
												} else {
													this.$set(cctvElement, 'checked', false);
												}
											}
										}
									}
								}
							}
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else if (data.spriteCssClass == 'site') {
				setTimeout(function () {
					// site 클릭 시 옆에 아이콘 클릭과 같은 효과 주기
					$(e.node).find('.k-icon').trigger('click');
					// 클릭 시 생기는 파란선 없애기
					$(e.node).find('.k-state-selected').removeClass('k-state-selected');
					// 클릭 시 생기는 옆에 테두리 없애기
					$(e.node).find('.k-state-focused').removeClass('k-state-focused');
				});
			}
		},
		// 관계 등록
		insSpeakerCctv() {
			if (confirm('적용하시겠습니까?')) {
				this.$axios
					.post(speakerCctvApi.updSpeakerApplyInfo, this.speakerInfo)
					.then(
						function () {
							this.$axios
								.post(speakerCctvApi.insSpeakerCctv, this.cctvInfo)
								.then(
									function () {
										alert('적용되었습니다.');
									}.bind(this),
								)
								.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		// 전체 선택 버튼 클릭 시
		changeAllChecked() {
			if (this.cctvInfo.length) {
				for (let cctvElement of this.cctvInfo) {
					if (!this.clickedAllCheckedEvent) {
						this.$set(cctvElement, 'checked', true);
					} else {
						this.$set(cctvElement, 'checked', false);
					}
				}
			}
		},
	},
};
</script>

<style>
[v-cloak] > * {
	display: none !important;
}

#rootVM > div > div {
	flex: 1;
	border: 1px solid #ddd;
	display: inline-flex;
	flex-direction: column;
}

#rootVM > div > .content {
	width: 79%;
	height: 890px;
	padding: 10px;
}

#treeViewExample {
	width: 20%;
	height: 890px;
	margin-right: 1%;
	font-size: 15px;
	font-weight: bold;
	line-height: 15px;
}

@media screen and (max-width: 680px) {
	.treeview-flex {
		flex: auto !important;
		width: 100%;
	}
}

#demo-section-title h3 {
	margin-bottom: 2em;
	text-align: center;
}

.treeview-flex h4 {
	color: #656565;
	margin-bottom: 1em;
	text-align: center;
}

#demo-section-title {
	width: 100%;
	flex: auto;
}

.treeview-flex {
	flex: 1;
	-ms-flex: 1 0 auto;
}

.k-treeview {
	max-width: 240px;
	margin: 0 auto;
}

.demo-section {
	margin-bottom: 5px;
	overflow: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.switch_wrapper {
	position: relative;
	float: right;
	padding-top: 5px;
}
.switch {
	position: absolute;
	/* hidden */
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.switch_label {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 60px;
	height: 30px;
	background: #262b40;
	border: 2px solid #262b40;
	border-radius: 20px;
	transition: 0.2s;
}
.switch_label > .off {
	text-align: right;
	padding-top: 3px;
	padding-right: 6px;
	color: white;
}
.onf_btn {
	position: absolute;
	top: 2px;
	left: 6px;
	display: inline-block;
	width: 21px;
	height: 21px;
	border-radius: 20px;
	background: rgb(255, 255, 255);
	transition: 0.2s;
}

.cctv-on {
	background: #46aef0e1;
	color: #333333;
}
.cctv-off {
	background: #262b40;
	color: white;
}

.disable {
	pointer-events: none;
	background: #ddd !important;
	opacity: 0.5;
}
.switch:checked + .switch_label {
	background: #46aef0e1;
	border: 2px solid #2eadff;
}
.switch:checked + .switch_label > .on {
	text-align: left;
	padding-top: 3px;
	padding-left: 10px;
}

/* move */
.switch:checked + .switch_label .onf_btn {
	left: 30px;
	background: #f5f5f5;
	box-shadow: 1px 2px 3px #00000020;
}
.table {
	table-layout: fixed;
}

.table-wrapper {
	border-collapse: collapse;
	overflow-x: auto;
	overflow-y: auto;
	border-color: #dedee0;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	position: relative;
	color: #515967;

	background-color: #fff;
}

/* table head */
.table-wrapper .table > thead > tr > th {
	background-color: #f5f5f5;

	text-align: center;

	padding: 0.5em 0.6em 0.4em 0.6em;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;

	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;

	overflow: auto;
	position: sticky;
	top: 0;
	box-shadow: inset 0 -1px 0 #dedee0;
}

.table-wrapper .table > thead > tr > th:first-child {
	border-width: 0 0 0 0;
}

/* table body */
/* 홀수 */
.table-wrapper .table > tbody > tr:nth-child(2n + 1) {
	background-color: #fff;
}
/* 짝수 */
.table-wrapper .table > tbody > tr:nth-child(2n) {
	background-color: #fbfbfb;
}

.table-wrapper .table > tbody > tr:hover {
	background-color: #b5bdcb;
	background-image: url(/lib/kendo-r2-2019/styles/textures/highlight.png);
	background-position: 50% 50%;
}

.table-wrapper .table > tbody > tr > td:first-child {
	border-width: 0 0 0 0;
}

.table-wrapper .table > tbody > tr > td {
	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;
	padding: 0.4em 0.6em;
	overflow: hidden;
	line-height: 1.6em;
	vertical-align: middle;
	text-overflow: ellipsis;
}

.table-wrapper .table > tbody > tr > td {
	text-align: center;
}

/* table empty */
.table [empty] {
	display: none;
	height: 166px;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.site {
	background-size: 100%;
	background-image: url('/images/icon-site.png');
}

.speaker {
	background-size: 100%;
	background-image: url('/images/icon-speaker.png');
}
</style>
